import React from 'react';
import Descriptor from '../../../Components/Descriptor';
import Image from '../../../Components/Image';
import { SOCIANA_URL } from '../../../constants';
import OURBRANDSjpg from '../../../assets/brand_sociana/event-image.jpg';
import SOCIANApng from '../../../assets/brand_sociana/ourbrands-logo2.png';
import RECTANGLEpng from '../../../assets/brand_sociana/square.png';
import OURBRANDSwebp from '../../../assets/brand_sociana/event-image.webp';
import SOCIANAwebp from '../../../assets/brand_sociana/ourbrands-logo2.webp';
import RECTANGLEwebp from '../../../assets/brand_sociana/square.webp';
import styles from './Sociana.module.css';

const description =
  "Sociana is an end to end event experience application that helps to host, manage, assist and organize multiple events and occasions from a centralised platform. It is a  convenient and extremely helpful application for event management companies, event manager, organizations and also for common people. Sociana consists of all ready tools which will help everyone who is looking to start their own events. A really great way to attend occasions or events staying away from family and friends and also to share memories with the loved ones. Guaranteed privacy controls for the event organizer from manageability to live streaming and also a great platform for marketing purposes with Ad Slots. Sociana is a value of money. What's a great way to revolutionize event experience ?? The answer is Sociana!!";
export default function Sociana() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.childContainer}>
        <div className={styles.squareImage}>
          <Image
            className={styles.square}
            webpImage={RECTANGLEwebp}
            src={RECTANGLEpng}
            alt="Background image"
          />
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.imageBox}>
            <Image
              className={styles.image}
              webpImage={OURBRANDSwebp}
              src={OURBRANDSjpg}
              alt="Brand"
            />
          </div>
        </div>
        <div className={styles.descriptor}>
          <Descriptor
            descriptorAlignment={styles.descriptorAlignment}
            maxDescriptionCharacters={407}
            description={description}
            knowMoreText="LEARN MORE"
            webpImage={SOCIANAwebp}
            image={SOCIANApng}
            alt="Sociana Logo"
            URL={SOCIANA_URL}
          />
        </div>
      </div>
    </div>
  );
}
